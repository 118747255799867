.nav {
  background: rgb(240, 238, 238);
  width: max-content;
  padding: 0.2rem 1rem;
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  border-radius: 2rem;
  backdrop-filter: blur(15px);
}

.nav__link {
  background: transparent;
  padding: 0.9rem;
  display: flex;
  color: grey;
  font-size: large;
}

.nav__link:hover {
  color: black;
}

.nav__link--active {
  background: lightgrey;
  color: black;
  border-radius: 2rem;
}
