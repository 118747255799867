.about__container {
  padding: 4rem 0;
  display: flex;
  justify-content: space-between;
}

.about__header {
  margin: 10% auto 2%;
  font-size: 20pt;
  font-weight: bold;
}

.about__text {
  line-height: 1.5;
  color: rgb(112, 112, 112);
  font-size: 20pt;
  font-weight: normal;
}

.about__image {
  width: 200%;
}


/* -------- MEDIA (MEDIUM DEVICES) -------- */

@media screen and (max-width: 1024px) {
  .about__container {
    flex-direction: column;
  }

  .about__image {
    width: fit-content;
    margin-top: -8rem;
  }

  .about__header {
    margin: -5rem auto 1.2rem;
    padding: 0;
  }
}


/* -------- MEDIA (SMALL DEVICES) -------- */

@media screen and (max-width: 600px) {
  .about__container {
    flex-direction: column;
    gap: -1rem;
  }

  .about__header {
    margin-top: -1rem;
    margin-bottom: 1.2rem;
    padding: 0;
  }

  .about__text {
    margin: 0;
  }
  
  .about__image {
    margin-top: -5rem;
    width: fit-content;
  }
}
