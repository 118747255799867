.contact__container{
  display: flex;
  gap: 10rem;
}

.contact__form {
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
  gap: 1.2rem;
  margin-top: 1rem;
}

.contact__input, .contact__textarea {
  width: 100%;
  padding: 1rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid #33ccff;
}

.contact__image-container {
  flex-basis: 10%;
  display: flex;
}

.contact__image {
  width: 200%;
}

.contact__success-message{
  color: var(--text-color);
}

.contact__button {
  display: flex;
  margin-top: 2rem;
  width: max-content;
  border: none;
  border-radius: 0.5rem;
  color: aliceblue;
  background-image: linear-gradient(to right, #57a3c9, #33ccff);
  padding: 0.75rem;
  cursor: pointer;
}


/* -------- MEDIA (MEDIUM DEVICES) -------- */

@media screen and (max-width: 1024px) {
  .contact__container {
    flex-direction: column;
    gap: 2rem;
  }

  .contact__image-container {
    flex-basis: auto;
    justify-content: center;
    margin-top: -5rem;
    margin-bottom: -15rem;
  }

  .contact__image {
    width: 80%;
    height: auto;
  }

  .contact__button {
    margin: 1rem auto auto;
    justify-content: center;
    font-size: x-large;
  }
}


/* -------- MEDIA (SMALL DEVICES) -------- */

@media screen and (max-width: 600px) {
  .contact__container {
    flex-direction: column;
    gap: 2rem;
  }

  .contact__form {
    flex-basis: auto;
    width: 100%;
  }

  .contact__image-container {
    flex-basis: auto;
    justify-content: center;
  }

  .contact__image {
    width: 100%;
    height: auto;
  }

  .contact__button {
    width: auto;
    margin: 1rem auto auto;
    justify-content: center;
  }
}
