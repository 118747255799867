.footer {
  position: relative;
  padding-top: 9rem;
  padding-bottom: 120px;
  font-size: 1rem;
  text-align: center;
  background-image: url('../../assets/images/wave.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 400%;
  overflow: hidden;
  color: var(--text-color-dark);
}

.footer__name h2 {
  margin: 1rem;
}

.footer__permalinks {
  display: flex;
  justify-content: center;
  gap: 3rem;
  margin: 0 auto 3rem;
}

.footer__permalinks a {
  color: black;
}

.footer__copy {
  display: flex;
  margin-top: -1.5rem;
  justify-content: center;
  gap: 0.5rem;
  color: black;
}

.footer__copy a {
  color: black;
}

/* -------- MEDIA (MEDIUM DEVICES) -------- */

@media (min-width: 601px) and (max-width: 1024px) {
  .footer {
    background-size: 800%;
  }

  .footer__permalinks a,
  .footer__copy {
    font-size: x-large;
  }
}

/* -------- MEDIA (SMALL DEVICES) -------- */

@media screen and (max-width: 600px) {
  .footer {
    margin-top: 5rem;
    background-size: 1000%;
  }

  .footer__permalinks {
    gap: 2rem;
  }

  .footer__copy {
    margin-right: 0.5rem;
  }
}
