@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');


* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

:root {

  /* Light theme colors */
  --background-color-light: white;
  --text-color-light: black;
  --portfolio-item-bg-light: white;

  /* Dark theme colors */
  --background-color-dark: rgb(33, 33, 33);
  --text-color-dark: white;
  --portfolio-item-bg-dark: black;

  /* Default theme colors */
  --background-color: var(--background-color-dark);
  --text-color: var(--text-color-dark);
  --portfolio-item-bg: var(--portfolio-item-bg-dark);


  --color-light: grey;
  --color-blue: blue;

  --container-width-lg: 75%;
  --container-widtth-md: 86%;
  --container-width-sm: 90%;

}
.App {
  display: flex;
  justify-content: center;
}

.appContainer {
  max-width: 1200px;
}

h1, h2, h3, h4, a, p, input, textarea, .swith {
  color: var(--text-color);
}

#light {
  --background-color: var(--background-color-light);
  --text-color: var(--text-color-light);
  --portfolio-item-bg: var(--portfolio-item-bg-light);
}

#light, #dark {
  background-color: var(--background-color);
}

.portfolio_item {
  background-color: var(--portfolio-item-bg);
}

.swith {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  margin-bottom: -2rem;
  gap: 1.5rem;

}

body {
  font-family: 'Poppins', sans-serif;
  background: var(--background-color);
  line-height: 1.7;
}

body.light {
  --background-color: var(--background-color-light);
}

body.dark {
  --background-color: var(--background-color-dark);
}


.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1, h2, h3 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

h4,h5 {
  font-weight: 400;
}

section > h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.text-light {
  color: var(--color-light);
}

img{
  display: block;
  width: 100%;
  object-fit: cover;
}

.btn {
  width: max-content;
  border: none;
  border-radius: 0.5rem;
  color: aliceblue;
  background-image: linear-gradient(to right, #57a3c9, #33ccff);
  padding: 0.75rem;
  cursor: pointer;
  margin: 10px;
  transition: background-image 0.3s ease;
}


/* -------- MEDIA (LARGE DEVICES) -------- */

@media screen and (max-width: 1439px) {
  .container {
    width: var(--container-widtth-lg);
  }
}


/* -------- MEDIA (MEDIUM DEVICES) -------- */

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-widtth-md);
  }
}


/* -------- MEDIA (SMALL DEVICES) -------- */

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }
}
