.item-card {
  padding: 1rem;
  margin: 0;
  box-shadow: 8px 8px 8px black;
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.item-card__title{
  height: 4rem;
}

.item-card__subtitle{
  height: 6rem;
  text-overflow: ellipsis;
}

.item-card__image-container {
    width: 100%;
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 1rem;
    overflow: hidden;
}

.item-card__link{
  height: 80%;
  width:100%;
  display: flex;
  align-items: center;
}

.item-card__image {
    display: block;
    width: 100%;
    margin: 0 auto;
    /* height: 80%;
    object-fit: fill; */
    gap: 2rem;
}

.item-card__subtitle, .item-card__tech-stack {
    color: var(--color-light);
    text-wrap: wrap;
  }

.item-card__tech-stack{
  max-width: fit-content;
  display: flex;
  align-items: center;
}

.item-card__cta {
  margin-bottom: 3rem;

  gap: 1.2rem;
  display: flex;
  justify-content: flex-start;
}
