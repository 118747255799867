.header {
  padding-top: 2rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  position: relative;
}

.header__cta {
  margin-top: 2.5rem;
  justify-content: center;
}

.header__socials {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 5rem;
  bottom: 15rem;
  margin: 3rem;
  gap: 1rem;
  font-size: x-large;
}

.header__socials::after {
  content: "";
  width: 2px;
  height: 1.5rem;
  background-color: var(--text-color);
}

.header__pic__container {
  background: none;
  width: 25rem;
  height: 30rem;
  position: relative; /* Change this if you specifically need it positioned inside a specific container */
  margin: 4rem auto; /* Auto margins can also help in centering */
  display: flex;
  justify-content: center;
  align-items: center;

}


.header__pic {
  max-width: 100%; /* Ensures the image does not exceed the container's width */
  max-height: 100%; /* Ensures the image does not exceed the container's height */
  object-fit: contain; /* Maintains aspect ratio and ensures the image fits within the container */
  border-radius: 10rem;
  box-shadow: 0 0 15px #00c6ff;
}

.header__scroll-down {
  position: absolute;
  right: 1rem;
  bottom: 20rem;
  transform: rotate(90deg);
  font-size: large;
}

.header__btn:visited {
  text-decoration: none;
}

.header__social-link:visited {
  text-decoration: none;
}

/* -------- MEDIA (MEDIUM DEVICES) -------- */
@media screen and (max-width: 1024px) {
  .header {
    min-height: 68vh;
  }
  .header__socials {
    width: 0%;
    margin-bottom: 0%;
  }

  .header__scroll-down {
    bottom: 18rem;
  }

  .header__pic {
    box-shadow: none;
  }
}


/* -------- MEDIA (SMALL DEVICES) -------- */
@media screen and (max-width: 600px) {
  .header {
    min-height: 100vh;
  }

  .header__socials,
  .header__scroll-down {
    display: none;
  }

  .header__pic {
    width: 20rem;
    height: 25rem;
    left: calc(50% - 10rem);
  }
}
